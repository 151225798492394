export const pincodeData = [
  { id: "421303", name: "421303" },
  { id: "401606", name: "401606" },
  { id: "421506", name: "421506" },
  { id: "401102", name: "401102" },
  { id: "401301", name: "401301" },
  { id: "401602", name: "401602" },
  { id: "421601", name: "421601" },
  { id: "400708", name: "400708" },
  { id: "401501", name: "401501" },
  { id: "421302", name: "421302" },
  { id: "421501", name: "421501" },
  { id: "400610", name: "400610" },
  { id: "401302", name: "401302" },
  { id: "401103", name: "401103" },
  { id: "401604", name: "401604" },
  { id: "421102", name: "421102" },
  { id: "401403", name: "401403" },
  { id: "401601", name: "401601" },
  { id: "421503", name: "421503" },
  { id: "421402", name: "421402" },
  { id: "400608", name: "400608" },
  { id: "401202", name: "401202" },
  { id: "401201", name: "401201" },
  { id: "400614", name: "400614" },
  { id: "421002", name: "421002" },
  { id: "401303", name: "401303" },
  { id: "421603", name: "421603" },
  { id: "401105", name: "401105" },
  { id: "401101", name: "401101" },
  { id: "421308", name: "421308" },
  { id: "401701", name: "401701" },
  { id: "421312", name: "421312" },
  { id: "401503", name: "401503" },
  { id: "400607", name: "400607" },
  { id: "401603", name: "401603" },
  { id: "401605", name: "401605" },
  { id: "401608", name: "401608" },
  { id: "401502", name: "401502" },
  { id: "400612", name: "400612" },
  { id: "401610", name: "401610" },
  { id: "401405", name: "401405" },
  { id: "400706", name: "400706" },
  { id: "421203", name: "421203" },
  { id: "421201", name: "421201" },
  { id: "401106", name: "401106" },
  { id: "421306", name: "421306" },
  { id: "401206", name: "401206" },
  { id: "401203", name: "401203" },
  { id: "421403", name: "421403" },
  { id: "400701", name: "400701" },
  { id: "401107", name: "401107" },
  { id: "401702", name: "401702" },
  { id: "400602", name: "400602" },
  { id: "401208", name: "401208" },
  { id: "421505", name: "421505" },
  { id: "400606", name: "400606" },
  { id: "400703", name: "400703" },
  { id: "401304", name: "401304" },
  { id: "400605", name: "400605" },
  { id: "421301", name: "421301" },
  { id: "401607", name: "401607" },
  { id: "421602", name: "421602" },
  { id: "400615", name: "400615" },
  { id: "421401", name: "421401" },
  { id: "401204", name: "401204" },
  { id: "401401", name: "401401" },
  { id: "401402", name: "401402" },
  { id: "421605", name: "421605" },
  { id: "421204", name: "421204" },
  { id: "421311", name: "421311" },
  { id: "400709", name: "400709" },
  { id: "400603", name: "400603" },
  { id: "401703", name: "401703" },
  { id: "421103", name: "421103" },
  { id: "400710", name: "400710" },
  { id: "401207", name: "401207" },
  { id: "401209", name: "401209" },
  { id: "421502", name: "421502" },
  { id: "421101", name: "421101" },
  { id: "401404", name: "401404" },
  { id: "401504", name: "401504" },
  { id: "401609", name: "401609" },
  { id: "401506", name: "401506" },
  { id: "400601", name: "400601" },
  { id: "421004", name: "421004" },
  { id: "421005", name: "421005" },
  { id: "421305", name: "421305" },
  { id: "401305", name: "401305" },
  { id: "421202", name: "421202" },
  { id: "400604", name: "400604" },
  { id: "402305", name: "402305" },
  { id: "410206", name: "410206" },
  { id: "402303", name: "402303" },
  { id: "402403", name: "402403" },
  { id: "402103", name: "402103" },
  { id: "402401", name: "402401" },
  { id: "402203", name: "402203" },
  { id: "402126", name: "402126" },
  { id: "410221", name: "410221" },
  { id: "402204", name: "402204" },
  { id: "402201", name: "402201" },
  { id: "402307", name: "402307" },
  { id: "402107", name: "402107" },
  { id: "402101", name: "402101" },
  { id: "402301", name: "402301" },
  { id: "410201", name: "410201" },
  { id: "402106", name: "402106" },
  { id: "410207", name: "410207" },
  { id: "402113", name: "402113" },
  { id: "402109", name: "402109" },
  { id: "410205", name: "410205" },
  { id: "402208", name: "402208" },
  { id: "402114", name: "402114" },
  { id: "402104", name: "402104" },
  { id: "402306", name: "402306" },
  { id: "402120", name: "402120" },
  { id: "402404", name: "402404" },
  { id: "402302", name: "402302" },
  { id: "402308", name: "402308" },
  { id: "402111", name: "402111" },
  { id: "402110", name: "402110" },
  { id: "400702", name: "400702" },
  { id: "410101", name: "410101" },
  { id: "402202", name: "402202" },
  { id: "402122", name: "402122" },
  { id: "402105", name: "402105" },
  { id: "415213", name: "415213" },
  { id: "402304", name: "402304" },
  { id: "402102", name: "402102" },
  { id: "402117", name: "402117" },
  { id: "410208", name: "410208" },
  { id: "402402", name: "402402" },
  { id: "410203", name: "410203" },
  { id: "400001", name: "400001" },
  { id: "402209", name: "402209" },
  { id: "410216", name: "410216" },
  { id: "400707", name: "400707" },
  { id: "402115", name: "402115" },
  { id: "410218", name: "410218" },
  { id: "402309", name: "402309" },
  { id: "410202", name: "410202" },
  { id: "410210", name: "410210" },
  { id: "410204", name: "410204" },
  { id: "402108", name: "402108" },
  { id: "402112", name: "402112" },
  { id: "410102", name: "410102" },
  { id: "410220", name: "410220" },
  { id: "410222", name: "410222" },
  { id: "400704", name: "400704" },
  { id: "402125", name: "402125" },
  { id: "402116", name: "402116" },
  { id: "402207", name: "402207" },
  { id: "400058", name: "400058" },
  { id: "400093", name: "400093" },
  { id: "400080", name: "400080" },
  { id: "400050", name: "400050" },
  { id: "400051", name: "400051" },
  { id: "400090", name: "400090" },
  { id: "400084", name: "400084" },
  { id: "400004", name: "400004" },
  { id: "400062", name: "400062" },
  { id: "400063", name: "400063" },
  { id: "400002", name: "400002" },
  { id: "400003", name: "400003" },
  { id: "400005", name: "400005" },
  { id: "400006", name: "400006" },
  { id: "400007", name: "400007" },
  { id: "400008", name: "400008" },
  { id: "400009", name: "400009" },
  { id: "400010", name: "400010" },
  { id: "400011", name: "400011" },
  { id: "400012", name: "400012" },
  { id: "400013", name: "400013" },
  { id: "400014", name: "400014" },
  { id: "400015", name: "400015" },
  { id: "400016", name: "400016" },
  { id: "400017", name: "400017" },
  { id: "400018", name: "400018" },
  { id: "400019", name: "400019" },
  { id: "400020", name: "400020" },
  { id: "400021", name: "400021" },
  { id: "400022", name: "400022" },
  { id: "400023", name: "400023" },
  { id: "400024", name: "400024" },
  { id: "400025", name: "400025" },
  { id: "400026", name: "400026" },
  { id: "400027", name: "400027" },
  { id: "400028", name: "400028" },
  { id: "400029", name: "400029" },
  { id: "400030", name: "400030" },
  { id: "400031", name: "400031" },
  { id: "400032", name: "400032" },
  { id: "400033", name: "400033" },
  { id: "400034", name: "400034" },
  { id: "400035", name: "400035" },
  { id: "400036", name: "400036" },
  { id: "400037", name: "400037" },
  { id: "400038", name: "400038" },
  { id: "400039", name: "400039" },
  { id: "400041", name: "400041" },
  { id: "400042", name: "400042" },
  { id: "400043", name: "400043" },
  { id: "400049", name: "400049" },
  { id: "400052", name: "400052" },
  { id: "400054", name: "400054" },
  { id: "400055", name: "400055" },
  { id: "400056", name: "400056" },
  { id: "400057", name: "400057" },
  { id: "400059", name: "400059" },
  { id: "400060", name: "400060" },
  { id: "400061", name: "400061" },
  { id: "400064", name: "400064" },
  { id: "400065", name: "400065" },
  { id: "400066", name: "400066" },
  { id: "400067", name: "400067" },
  { id: "400068", name: "400068" },
  { id: "400069", name: "400069" },
  { id: "400070", name: "400070" },
  { id: "400071", name: "400071" },
  { id: "400072", name: "400072" },
  { id: "400073", name: "400073" },
  { id: "400074", name: "400074" },
  { id: "400075", name: "400075" },
  { id: "400076", name: "400076" },
  { id: "400077", name: "400077" },
  { id: "400078", name: "400078" },
  { id: "400079", name: "400079" },
  { id: "400081", name: "400081" },
  { id: "400082", name: "400082" },
  { id: "400083", name: "400083" },
  { id: "400085", name: "400085" },
  { id: "400086", name: "400086" },
  { id: "400087", name: "400087" },
  { id: "400088", name: "400088" },
  { id: "400089", name: "400089" },
  { id: "400091", name: "400091" },
  { id: "400092", name: "400092" },
  { id: "400094", name: "400094" },
  { id: "400095", name: "400095" },
  { id: "400096", name: "400096" },
  { id: "400097", name: "400097" },
  { id: "400098", name: "400098" },
  { id: "400099", name: "400099" },
  { id: "400101", name: "400101" },
  { id: "400102", name: "400102" },
  { id: "400103", name: "400103" },
  { id: "400104", name: "400104" },
  { id: "400611", name: "400611" },
  { id: "412803", name: "412803" },
  { id: "416301", name: "416301" },
  { id: "203125", name: "203125" },
  { id: "422401", name: "422401" },
  { id: "422605", name: "422605" },
  { id: "400705", name: "400705" },
  { id: "412206", name: "412206" },
  { id: "413511", name: "413511" },
  { id: "412203", name: "412203" },
  { id: "412104", name: "412104" },
  { id: "441906", name: "441906" },
  { id: "414604", name: "414604" },
];
